.menu {
    -webkit-overflow-scrolling: touch;
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4300;
    
    .menu_bg {
        animation-delay: 0s;
        animation-direction: normal;
        animation-duration: .1ms;
        animation-fill-mode: both;
        animation-iteration-count: 1;
        animation-name: menu-out;
        animation-timing-function: cubic-bezier(.14,1,.34,1);
        background-color: rgb(49,60, 66);
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
    .menu_item:hover .item-body {
        color: rgb(0,149,125);
    }
    
   
}

.menu.show {
    pointer-events: auto;
}
.menu.show .menu_bg {
    animation-delay: 0s;
    animation-direction: normal;
    animation-duration: .4s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: menu-in;
    animation-timing-function: cubic-bezier(.23,1,.32,1);
}
.init .menu_bg {
    animation-duration: .5s;
}
.menu.show .menu_bg:before {
    transform: scaleX(0);
    transition: .6s cubic-bezier(.645,.045,.355,1) .08s;
}
.menu_bg:before {
    background-color: rgb(0,149,125);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(1);
    transform-origin: 100% 0;
    transition: .8s cubic-bezier(.165,.84,.44,1);
    width: 100%;
}
.menu.show .menu_inner {
    opacity: 1;
    transition: .5s cubic-bezier(.215,.61,.355,1) .1s;
}
.menu_inner {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    height: auto;
    justify-content: flex-start;
    opacity: 0;
    // padding: 36% 0 32px;
    position: relative;
    transition: .3s cubic-bezier(.14,1,.34,1);
    width: auto;
    z-index: 10;
}
.menu_item {
    display: inline-block;
    margin: 5px 0;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
}
.show{
    .menu_item {
        opacity: 1;
        transform: translateY(0);
    }
    .menu_item:first-child {
        transition: .5s cubic-bezier(.215,.61,.355,1) .23s;
    }
    .menu_item:nth-child(2) {
        transition: .5s cubic-bezier(.215,.61,.355,1) .26s;
    }
    .menu_item:nth-child(3){
        transition: .5s cubic-bezier(.215,.61,.355,1) .29s;
    }
    .menu_item:nth-child(4) {
        transition: .5s cubic-bezier(.215,.61,.355,1) .32s;
    }
    .menu_item:nth-child(5) {
        transition: .5s cubic-bezier(.215,.61,.355,1) .35s;
    }
    .menu_item:nth-child(6) {
        transition: .5s cubic-bezier(.215,.61,.355,1) .41s;
    }
    .menu_item:nth-child(7) {
        transition: .5s cubic-bezier(.215,.61,.355,1) .44s;
    }
    .menu_links{
        .label_link {
            opacity: 1;
            transform: translateY(0);
        }
        .label_link:first-child {
            transition: .5s cubic-bezier(.215,.61,.355,1) .55s;
        }
        .label_link:nth-child(2) {
            transition: .5s cubic-bezier(.215,.61,.355,1) .6s;
        }
    }
    .menu_shared{
        img{
            opacity: 1;
            transform: translateY(0);
            transition: .5s cubic-bezier(.215,.61,.355,1) .65s;
        }
    }
} 
.label{
    color: inherit;
    font-family: inherit;
    font-size: 1.26667rem;
    letter-spacing: 0;
    line-height: 1;
}
.label_text{
    font-size: .20rem;
}
.item-body{
    color: #fff;
    .label{
        
        font-family: Cabin-Bold,sans-serif;
        font-weight: 700;
        font-size: 5.66667rem;
        font-size: 6vh;
        letter-spacing: -.01em;
        line-height: 1;
        line-height: 5.7vh;
        transition: all .5s cubic-bezier(.4,.8,.74,1) 0s;
    }
}
.menu_links {
    margin: 4vh -16px 0;
   font-size: 25px;
    .label_link{
        color: #fff;
        font-family: PT_Sans-Web-Bold,sans-serif;
        font-size: 0.2rem;
        letter-spacing: 0;
        line-height: 1.6;
        position: relative;
        transition: all .5s cubic-bezier(.4,.8,.74,1) 0s;
        margin: 0 16px;
        opacity: 0;
        transform: translateY(50px);
        display: inline-block;
        p{
            opacity: 0.5;
        }
    }
    .label_link:hover p{
        opacity: 1;
    }
}

.menu_shared {
    margin: 3.8vh -8px 0;
    font-size: 25px;
    a {
        opacity: .5;
    }
    img{
        color: #fff;
        display: inline-block;
        font-size: 20px;
        margin: 0 0.08rem;
        opacity: 0;
        transform: translateY(50px);
    }
    a,  a img {
        transition: all .5s cubic-bezier(.4,.8,.74,1) 0s;
    }
}

@keyframes menu-in {
    0%{
        transform: translate(-100%);
    }
    100% {
        transform: translate(0);
    }
}
@keyframes menu-out {
    0%{
        transform: translate(0);
    }
    100% {
        transform: translate(100%);
    }
}
@media (max-height: 900px) and (min-width: 992px){
    .item-body{
        .label {
            font-size: 9vh;
            line-height: 8.7vh;
        }
    }
}
@media (min-width: 992px){
    .menu-button {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        margin-top: 42px;
        .menu_links {
            margin-top: 0;
        }
        .menu_shared {
            margin-left: 47px;
            margin-top: 0;
        }
    }
    .menu_links {
        .label_link{
            font-size: .3rem;
            letter-spacing: 0;
            line-height: 1;
        }
    }
    .item-body{
        .label {
            font-size: 9vh;
            line-height: 8.7vh;
        }
    }
    .label_text{
        font-size: 0.3rem;
    }
   
}
@media  (max-width: 992px){
    .menu{
        height: 100vh;
    }
  
    
   
}

* {
  margin: 0;
  padding: 0;
}
html{height:100%;}
body{height:100%;font-family:'Helvetica Neue','Helvetica','PingFang SC','Hiragino Sans GB','Microsoft YaHei',Arial,sans-serif;color:#333;line-height:1.5;word-wrap:break-word;word-break:break-all;overflow-x:hidden;overflow-x:auto;}
*{margin:0;padding:0;box-sizing:border-box;outline:0;-webkit-text-size-adjust:100%;-webkit-tap-highlight-color:rgba(0,0,0,0);}
a{text-decoration:none;color:inherit;}
li{list-style:none;}
h1,h2,h3,h4,h5,h6{font-size:inherit;font-weight:inherit;}
button,input,select,textarea{border:0;border-radius:0;background-color:transparent;-webkit-appearance:none;color:inherit;}
input:disabled,textarea:disabled{background-color:#f1f1f1;}
:focus::-webkit-input-placeholder{color:transparent;}
style{display:none!important;}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
